<template>
<div class="buttonGroup">
    <el-radio-group @change="handleClick" v-model="odata">
      <el-radio-button :key="key" v-for="(item,key) in buttonData" :label="item"></el-radio-button>
    </el-radio-group>
</div>
</template>

<script>
export default {
  name: 'TableButtonGroup',
  props: ['buttonData', 'defaultData'],
  data: function () {
    return { odata: this.defaultData }
  },
  methods: {
    handleClick (data) {
      this.$emit('onChange', data)
    }
  }
}
</script>

<style scoped>
.buttonGroup{
    height: 60px;
    line-height: 60px;
    padding-left: 60px;
}
</style>
